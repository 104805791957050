<template>
  <main class="no-main">
    <section class="section--order-tracking">
      <div class="container">
        <h2 class="page__title"></h2>
        <!-- Fail Message -->
        <div class="order-tracking__content">
          <div class="order-tracking__form">
            <form>
              <div class="form-row">                
                <div class="col-12 text">
                <img  class="okpng mx-auto d-block" src="@/assets/img/fail.png" alt="ok">
                <p class="failed_title mt-3">Payment Failed</p>
                </div>                
                <div class="col-12 message_content">
                  <p>
                    Your payment has not been processed. <br> if you would like to return to your shopping cart
                  </p>
                </div>
                <div class="col-12 form-group-block">
                  <router-link class="btn ps-button" :to="{ name: 'home' }">
                    Back to home
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default{
    
};
</script>

<style lang="scss" scoped>
.icon-check{
  width: 400px;
}
.okpng{
  width: 100px;
}
.success_title{
    color: #679A40;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
}
.failed_title{
    color: #D92123;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
}
.message_content p{
    text-align: center;
}
</style>